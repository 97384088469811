import React from "react";
import { motion } from "framer-motion";
import {
  FileSearch,
  Palette,
  Code,
  TestTube,
  Rocket,
  Wrench,
} from "lucide-react";

const ProcessStep = ({ icon, title, description, step }) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    className="relative flex flex-col items-center p-6"
  >
    <div className="absolute top-1/2 w-full border-t-2 border-emerald-200 dark:border-emerald-800 -z-10" />
    <div className="bg-white dark:bg-gray-800 rounded-full p-4 mb-4 border-2 border-emerald-500">
      {icon}
    </div>
    <span className="text-emerald-500 font-bold mb-2">STEP {step}</span>
    <h3 className="text-xl font-semibold mb-2 text-center">{title}</h3>
    <p className="text-gray-600 dark:text-gray-400 text-center text-sm">
      {description}
    </p>
  </motion.div>
);

const Process = () => {
  const steps = [
    {
      icon: <FileSearch className="w-8 h-8 text-emerald-500" />,
      title: "요구사항 분석",
      description: "고객의 니즈를 정확히 파악하고 최적의 솔루션을 제안합니다.",
    },
    {
      icon: <Palette className="w-8 h-8 text-emerald-500" />,
      title: "UI/UX 설계",
      description: "사용자 중심의 직관적인 인터페이스를 설계합니다.",
    },
    {
      icon: <Code className="w-8 h-8 text-emerald-500" />,
      title: "개발",
      description:
        "최신 기술을 활용하여 안정적이고 확장 가능한 시스템을 구축합니다.",
    },
    {
      icon: <TestTube className="w-8 h-8 text-emerald-500" />,
      title: "테스트",
      description: "철저한 품질 관리로 안정성을 보장합니다.",
    },
    {
      icon: <Rocket className="w-8 h-8 text-emerald-500" />,
      title: "배포",
      description: "최적화된 환경에서 서비스를 론칭합니다.",
    },
    {
      icon: <Wrench className="w-8 h-8 text-emerald-500" />,
      title: "유지보수",
      description: "지속적인 모니터링과 업데이트로 서비스를 개선합니다.",
    },
  ];

  return (
    <section id="process" className="py-20 bg-gray-50 dark:bg-gray-900">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
            개발 프로세스
          </h2>
          <p className="text-gray-600 dark:text-gray-400 max-w-2xl mx-auto">
            체계적인 프로세스로 프로젝트의 성공을 보장합니다
          </p>
        </motion.div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-8">
          {steps.map((step, index) => (
            <ProcessStep key={index} {...step} step={index + 1} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Process;
