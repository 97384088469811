import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, ChevronUp } from 'lucide-react';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200 dark:border-gray-700">
      <button
        className="w-full py-4 flex justify-between items-center text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-lg font-medium text-gray-900 dark:text-white">{question}</span>
        {isOpen ? (
          <ChevronUp className="text-emerald-500" />
        ) : (
          <ChevronDown className="text-emerald-500" />
        )}
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            className="overflow-hidden"
          >
            <p className="pb-4 text-gray-600 dark:text-gray-400">{answer}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const FAQ = () => {
  const faqs = [
    {
      question: "프로젝트 견적은 어떻게 산정되나요?",
      answer: "프로젝트의 규모, 복잡도, 기술 요구사항, 개발 기간 등을 종합적으로 고려하여 견적을 산출합니다. 초기 상담을 통해 상세한 견적을 제공해 드립니다."
    },
    {
      question: "프로젝트 진행 기간은 얼마나 걸리나요?",
      answer: "일반적으로 소규모 프로젝트는 1-2개월, 중규모 프로젝트는 3-4개월 정도 소요됩니다. 정확한 기간은 요구사항 분석 후 안내해 드립니다."
    },
    {
      question: "유지보수는 어떻게 진행되나요?",
      answer: "프로젝트 완료 후 6개월간 무상 유지보수를 제공하며, 이후에는 별도의 유지보수 계약을 통해 지속적인 관리가 가능합니다."
    },
    {
      question: "기술 스택은 어떻게 결정되나요?",
      answer: "프로젝트의 특성, 확장성, 유지보수 용이성 등을 고려하여 최적의 기술 스택을 제안해 드립니다. 고객사의 기존 시스템과의 호환성도 고려됩니다."
    }
  ];

  return (
    <section id="faq" className="py-20 bg-gray-50 dark:bg-gray-900">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
            자주 묻는 질문
          </h2>
          <p className="text-gray-600 dark:text-gray-400 max-w-2xl mx-auto">
            고객님들이 자주 문의하시는 내용들을 모았습니다
          </p>
        </motion.div>
        <div className="max-w-3xl mx-auto">
          {faqs.map((faq, index) => (
            <FAQItem key={index} {...faq} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ; 