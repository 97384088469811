import React from 'react';
import { motion } from 'framer-motion';
import { Users, CheckCircle, Clock, Star } from 'lucide-react';

const StatCard = ({ icon, value, label }) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg text-center"
  >
    <div className="text-emerald-500 mb-4 flex justify-center">{icon}</div>
    <motion.h3
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      className="text-3xl font-bold text-gray-900 dark:text-white mb-2"
    >
      {value}
    </motion.h3>
    <p className="text-gray-600 dark:text-gray-400">{label}</p>
  </motion.div>
);

const Stats = () => {
  const stats = [
    {
      icon: <Users size={40} />,
      value: "50+",
      label: "만족한 고객"
    },
    {
      icon: <CheckCircle size={40} />,
      value: "100+",
      label: "완료된 프로젝트"
    },
    {
      icon: <Clock size={40} />,
  value: "7년+",
      label: "개발 경력"
    },
    {
      icon: <Star size={40} />,
  value: "4.8",
      label: "평균 고객 만족도"
    }
  ];

  return (
    <section className="py-20 bg-emerald-50 dark:bg-gray-800">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {stats.map((stat, index) => (
            <StatCard key={index} {...stat} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Stats; 