import React from 'react';
import { motion } from 'framer-motion';

const SkillCategory = ({ title, skills }) => (
  <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
    <h3 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white">{title}</h3>
    <div className="space-y-4">
      {skills.map((skill, index) => (
        <div key={index}>
          <div className="flex justify-between mb-1">
            <span className="text-sm font-medium text-gray-700 dark:text-gray-300">{skill.name}</span>
            <span className="text-sm text-gray-600 dark:text-gray-400">{skill.level}%</span>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
            <motion.div
              initial={{ width: 0 }}
              whileInView={{ width: `${skill.level}%` }}
              viewport={{ once: true }}
              transition={{ duration: 1 }}
              className="bg-emerald-500 h-2.5 rounded-full"
            />
          </div>
        </div>
      ))}
    </div>
  </div>
);

const Skills = () => {
  const skillCategories = [
    {
      title: "Frontend",
      skills: [
        { name: "React/Next.js", level: 90 },
        { name: "Vue.js", level: 85 },
        { name: "TypeScript", level: 85 },
        { name: "Tailwind CSS", level: 90 }
      ]
    },
    {
      title: "Backend",
      skills: [
        { name: "Node.js", level: 90 },
        { name: "Express", level: 85 },
        { name: "GraphQL", level: 80 },
        { name: "MySQL/PostgreSQL", level: 85 }
      ]
    },
    {
      title: "Mobile",
      skills: [
        { name: "React Native", level: 85 },
        { name: "Android Native", level: 75 },
        { name: "Expo", level: 80 }
      ]
    },
    {
      title: "DevOps",
      skills: [
        { name: "Docker", level: 80 },
        { name: "AWS/NCP", level: 75 },
        { name: "CI/CD", level: 75 }
      ]
    }
  ];

  return (
    <section id="skills" className="py-20 bg-gray-100 dark:bg-gray-900">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
            기술 스택
          </h2>
          <p className="text-gray-600 dark:text-gray-400 max-w-2xl mx-auto">
            다양한 기술 스택을 활용하여 최적의 솔루션을 제공합니다
          </p>
        </motion.div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {skillCategories.map((category, index) => (
            <SkillCategory key={index} {...category} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Skills; 