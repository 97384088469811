import React from 'react';
import { Github, Linkedin } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-gray-300">
      <div className="container mx-auto px-6 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="space-y-4">
            <h3 className="text-2xl font-bold text-white">GuriOut</h3>
            <p className="text-sm">혁신적인 디지털 솔루션으로 비즈니스의 미래를 만들어갑니다.</p>
          </div>
          
          <div>
            <h4 className="text-lg font-semibold mb-4 text-white">서비스</h4>
            <ul className="space-y-2">
              <li><a href="#services" className="hover:text-emerald-400 transition-colors">웹 개발</a></li>
              <li><a href="#services" className="hover:text-emerald-400 transition-colors">모바일 앱</a></li>
              <li><a href="#services" className="hover:text-emerald-400 transition-colors">IoT 솔루션</a></li>
            </ul>
          </div>
          
          <div>
            <h4 className="text-lg font-semibold mb-4 text-white">문의하기</h4>
            <ul className="space-y-2">
              <li>이메일: contact@guriout.com</li>
              <li>전화: 010-1234-5678</li>
              <li>카카오톡: @guriout</li>
            </ul>
          </div>
          
          <div>
            <h4 className="text-lg font-semibold mb-4 text-white">팔로우</h4>
            <div className="flex space-x-4">
              <a href="#" className="text-emerald-500 hover:text-emerald-400">
                <Github size={24} />
              </a>
              <a href="#" className="text-emerald-500 hover:text-emerald-400">
                <Linkedin size={24} />
              </a>
            </div>
          </div>
        </div>
        
        <div className="border-t border-gray-800 mt-8 pt-8 text-center text-sm">
          <p>&copy; 2024 GuriOut. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;