import React, { useState } from "react";
import { motion } from "framer-motion";
import { ExternalLink, Github } from "lucide-react";

const ProjectCard = ({
  title,
  description,
  period,
  image,
  demoUrl,
  githubUrl,
  tags,
  details,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <motion.div
      whileHover={{ scale: 1.02 }}
      className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden border border-gray-200 dark:border-gray-700"
    >
      <div className="p-6">
        <div className="flex items-center justify-between mb-2">
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
            {title}
          </h3>
          <div className="flex space-x-2">
            {demoUrl && (
              <a
                href={demoUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-emerald-600 hover:text-emerald-700 dark:text-emerald-400"
              >
                <ExternalLink size={20} />
              </a>
            )}
          
          </div>
        </div>
        {period && (
          <p className="text-sm text-gray-500 dark:text-gray-400 mb-2">
            {period}
          </p>
        )}
        <p className="text-gray-600 dark:text-gray-300 mb-4">{description}</p>
        {details && (
          <div className="mb-4">
            <div className="flex justify-between items-center mb-2">
              <p className="text-sm text-gray-500 dark:text-gray-400">{details.role}</p>
              <span className="text-emerald-500 cursor-pointer" onClick={() => setIsExpanded(!isExpanded)}>
                {isExpanded ? '간단히 보기' : '자세히 보기'}
              </span>
            </div>
            
            {isExpanded && (
              <div className="mt-4 space-y-4">
                <div>
                  <h4 className="font-semibold mb-2">주요 성과</h4>
                  <ul className="list-disc list-inside text-sm space-y-1">
                    {details.achievements.map((achievement, index) => (
                      <li key={index}>{achievement}</li>
                    ))}
                  </ul>
                </div>
                
                {details.metrics && (
                  <div>
                    <h4 className="font-semibold mb-2">성과 지표</h4>
                    <ul className="list-disc list-inside text-sm space-y-1">
                      {details.metrics.map((metric, index) => (
                        <li key={index}>{metric}</li>
                      ))}
                    </ul>
                  </div>
                )}
                
                {details.challenges && (
                  <div>
                    <h4 className="font-semibold mb-2">도전과 해결</h4>
                    <ul className="list-disc list-inside text-sm space-y-1">
                      {details.challenges.map((challenge, index) => (
                        <li key={index}>{challenge}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        <div className="flex flex-wrap gap-2">
          {tags.map((tag, index) => (
            <span
              key={index}
              className="px-3 py-1 text-sm bg-emerald-100 text-emerald-700 dark:bg-emerald-900/30 dark:text-emerald-300 rounded-full"
            >
              {tag}
            </span>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

const Portfolio = () => {
  const projects = [
    {
      title: "공업 모니터링 웹",
      description:
        "Node.js와 MQTT를 활용한 초당 4만 개의 데이터 실시간 처리 및 제어 시스템",
      period: "2023.11 ~ 2024.01 (8주)",
      image: "/images/industrial.jpg",
      tags: ["MQTT", "Express", "Node.js", "React", "SQL"],
      details: {
        role: "풀스택 개발자 (팀: 기획 1, 디자인 1, 퍼블리셔 1, 개발 1)",
        achievements: [
          "초당 4만 개 데이터 실시간 처리 시스템 구축",
          "트렌드 데이터 분석 대시보드 개발",
          "실시간 이상 신호 감지 알고리즘 구현",
        ],
      },
    },
    {
      title: "BIBLE V3.0 앱",
      description: "성경 읽기와 커뮤니티 기능을 결합한 종합 신앙 생활 앱",
      period: "2022.04 ~ 2022.05 (3주)",
      image: "/images/bible-app.jpg",
      tags: ["React Native", "Node.js", "Express", "SQL"],
      details: {
        role: "풀스택 개발자 (팀: 기획 1, 디자인 1, 개발 1)",
        achievements: [
          "피드 형식 커뮤니티 기능 구현",
          "성경 읽기 진척도 추적 시스템",
          "포인트 적립 및 교환 시스템 개발",
        ],
      },
    },
    {
      title: "Cloud Drive Service",
      description:
        "청크 기반 대용량 파일 업로드와 드래그 앤 드롭 기능을 갖춘 클라우드 스토리지",
      image: "/images/cloud-drive.jpg",
      tags: ["React", "Node.js", "MySQL", "Redis", "JWT"],
      details: {
        role: "풀스택 개발자 (개인 프로젝트)",
        achievements: [
          "청크 기반 대용량 파일 업로드 시스템",
          "@dnd-kit 기반 드래그 앤 드롭",
          "Redis 캐싱 최적화",
        ],
      },
    },
    {
      title: "자동 전화 관리 앱",
      description: "연락처 그룹 기반 스마트 자동 전화 예약 시스템",
      image: "/images/auto-call.jpg",
      tags: ["React Native", "Expo", "Android Native", "Kotlin"],
      details: {
        role: "풀스택 개발자 (개인 프로젝트)",
        achievements: [
          "WorkManager 기반 백그라운드 작업 스케줄링",
          "Custom Bridge 모듈 개발",
          "배터리 효율성 최적화",
        ],
      },
    },
    {
      title: "Guriout SSO",
      description: "JWT와 RSA256 알고리즘을 활용한 중앙화된 사용자 인증 시스템",
      image: "/images/sso.jpg",
      tags: ["React", "Node.js", "JWT", "MySQL"],
      details: {
        role: "풀스택 개발자 (개인 프로젝트)",
        achievements: [
          "자동 키 교체 시스템 구현",
          "다중 보안 계층 구현",
          "winston 기반 보안 로깅",
        ],
      },
    },
    {
      title: "Headless CMS",
      description: "동적 콘텐츠 타입 생성 및 관리가 가능한 Headless CMS",
      image: "/images/cms.jpg",
      tags: ["Vue.js", "Node.js", "Knex.js", "SQLite"],
      details: {
        role: "풀스택 개발자 (개인 프로젝트)",
        achievements: [
          "동적 콘텐츠 타입 생성 시스템",
          "자동 API 생성 시스템",
          "플러그인 아키텍처 설계",
        ],
      },
    },
  ];

  return (
    <section id="portfolio" className="py-20 bg-gray-50 dark:bg-gray-900">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
            포트폴리오
          </h2>
          <p className="text-gray-600 dark:text-gray-400 max-w-2xl mx-auto">
            최신 기술을 활용한 다양한 프로젝트들을 확인해보세요
          </p>
        </motion.div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {projects.map((project, index) => (
            <ProjectCard key={index} {...project} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
